@import "../../../utils/colors";
@import "../../../utils/typeography";

.SimpleTable {
  .SimpleTable-body {
    min-height: 500px;
  }
  .select__input {
    color: transparent;
  }

  .SimpleTable-tableHeader {
    border-bottom: 3px solid $gold-gradient-dark;
  }

  .SimpleTable-tableRow {
    border-bottom: 2px solid $background-blue-very-light;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}