@import "../../../utils/colors.scss";
@import "../../../utils/typeography.scss";


.Login-container {
  position: absolute;
  width: 100%;
  height: 100%;

  .Login-formBlock {
    margin-top: 15vh;
  }

  .Login-formButtonDisabled {
    width: 200px;
  }

  .Login-decorativeBackground {
    background: $taekus-purple;
  }

  .Login-title,
  .Login-subtitle {
    color: $taekus-purple;
    text-align: center;
    font-family: Monument Grotesk;
    font-weight: 400;
  }

  .Login-Input {
    border: none;
    border-radius: 0;
    outline: none;
    padding: 3px 6px;
    font-family: Monument Grotesk;
    border-bottom: 1px solid $taekus-grey;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;

    &.Login-GenderInput {
      border-radius: 0;
    }

    &:focus,
    &:hover,
    &:active {
      border-bottom: 1px solid $taekus-purple;
    }
  }

  .complianceCheckboxContainer {
    .custom-control-label {
      padding-top: 1.5px;
    }
    .custom-control-input:checked~.custom-control-label:before {
      background-color: $background-blue;
      border-color: $background-blue;
    }
    .custom-control-input:focus~.custom-control-label:before {
      border-color: $background-blue;
      box-shadow: 0 0 0 0.2rem $background-blue-light;
    }
  }

  .Login-logo {
    width: 240px;
    height: 200px;
    margin-top: 30vh;
  }

  .Taekus-Icon-Large {
    width: 200px;
    height: 200px;
  }

  .Login-glyph {
    margin-top: 4px;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
    outline: none;
    position: absolute;
    right: 15px;

    &.Login-revealPassword {
      background-image: url("/assets/old/img/global/eye.svg");
    }

    &.Login-hidePassword {
      background-image: url("/assets/old/img/global/closed-eye.svg");
    }

    &.Login-circleCheck {
      background-image: url("/assets/old/img/global/login-check.svg");
    }
  }
}

