@import "../../utils/colors.scss";
@import "../../utils/typeography.scss";


.NotificationBanner {
  border: none;
  background: $background-blue;
  color: $white;
  font-family: $font-dm-sans;
}
