@import "../../utils/colors.scss";
@import "../../utils/typeography.scss";

.datePicker {
  height: 100%;
  
  .react-datepicker-wrapper {
    height: 100%;
    width: 100%;

    .react-datepicker__input-container {
      height: 100%;
      width: 100%;
    }
  }

  .react-datepicker__tab-loop {

    .react-datepicker-popper {
      height: 280px;

      .react-datepicker * {
        outline: none;
      }

      .react-datepicker {
        border-radius: 0;
        border: 1px solid rgb(217, 217, 217);

        .react-datepicker__navigation {
          margin-top: 6px;
        }

        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range {
          background-color: rgba(247, 146, 124, 0.2);
          color: black;
          border-radius: 0;
          width: 2.032rem;
          margin: 0.166rem 0;
        }

        .react-datepicker__triangle {
          display: none;
        }

        .react-datepicker__header {
          background-color: transparent;
          border: none;
        }
        
        .react-datepicker__current-month {
          font-weight: 400;
          user-select: none;
        }
        
        .react-datepicker__day {
          font-family: 'Monument Grotesk';
          font-weight: bold;
          width: 2.032rem;
          margin: 0.166rem 0;
          position: relative;
        }

        .react-datepicker__day:hover {
          background-color: transparent;
        }

        .react-datepicker__day:hover::before,
        .react-datepicker__day--range-end::before,
        .react-datepicker__day--range-start::before,
        .react-datepicker__day--selected::before {
          border-radius: 50% !important;
          width: 1.7rem;
          margin: 0 0.166rem;
          content: "";
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1 !important;
          color: black;
          background-color: rgb(247, 146, 124) !important;
        }

        .react-datepicker__day--selecting-range-start::after,
        .react-datepicker__day--range-start::after {
          width: 50%;
          content: "";
          height: 100%;
          position: absolute;
          left: 50%;
          top: 0;
          z-index: 0 !important;
          background-color: rgb(254,233,229) !important;
        }
        
        .react-datepicker__day--selecting-range-end::after,
        .react-datepicker__day--range-end::after {
          width: 50%;
          content: "";
          height: 100%;
          position: absolute;
          right: 50%;
          top: 0;
          z-index: 0 !important;
          background-color: rgb(254,233,229) !important;
        }

        .react-datepicker__day span,
        .react-datepicker__day--range-end span,
        .react-datepicker__day--range-start span,
        .react-datepicker__day--selected span {
          position: relative;
          z-index: 5;
          color: black !important;
        }

        .react-datepicker__day--range-start,
        .react-datepicker__day--range-end,
        .react-datepicker__day--selected {
          position: relative;
          background-color: transparent;
          border-radius: 0;
          height: 1.7rem;
          color: black;
        }

        .react-datepicker__day--disabled {
          opacity: 0.5;
        }
      }
    }
  }
}