@import "../../../utils/colors.scss";
@import "../../../utils/typeography.scss";
@import "../../../utils/buttons.scss";


.VirtualCards {
  .VirtualCards-TransferButton {
    width: 80px;
    font-weight: 600;
  }

  .VirtualCardButtonBlue {
    background: $background-blue-very-light;
    color: $black;
    height: 40px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    filter: $button-drop-shadow;

    &:hover {
      border: 1px solid $background-blue-light;
      border-radius: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  .VirtualCardButtonGreyedOut {
    background: $white;
    color: $gray;
    border-radius: 5px;
    height: 40px;
    border: 1px solid $transparent-gray;

    &:hover {
      border: 1px solid $background-blue-light;
      border-radius: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  .VirtualCards-editButton {
    background: none;
    border: none;
    text-decoration: underline;
    color: $background-blue;

    &:focus,
    &:hover {
      outline: none;
    }

    svg {
      transform: scale(0.6);
      margin-bottom: 10px;
    }
  }
  .VirtualCards-nameInput {
    padding: 0.15rem 0.2rem 0.2rem 0.2rem;
    color: $foreground-blue;
    border: 1px solid $foreground-blue-inactive;
    border-radius: 0.25em;
    background-color: transparent;
    margin-left: -4.2px;

    &:focus {
      box-shadow: none;
      border-color: $gold-gradient-dark;
      outline: none;
    }
  }

  .Card-Search {
    border: 1px solid $foreground-blue;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $gold-gradient-dark;
    }
  }

  .VirtualCards-detailButton {
    color: $background-blue;
    font-weight: bold;
    font-family: $font-raleway;
  }


  .VirtualCards-eyeGlyph {
    margin-top: 4px;
    background-size: cover;
    background-position: center;
    background-color: white;
    border: none;
    width: 30px;
    height: 30px;
    outline: none;
    background-image: url("/assets/old/img/global/eye.svg");
  }
}


.VirtualCardSummary {
  .VirtualCardSummary-editButton {
    background: none;
    border: none;
    text-decoration: underline;
    color: $background-blue;

    &:focus,
    &:hover {
      outline: none;
    }
  }
  .VirtualCardSummary-nameInput {
    font-size: $h2-default-size;
    font-weight: 600;
    font-family: $font-raleway;
    padding: 0.15rem 0.2rem 0.2rem 0.2rem;
    max-height: 37px;
    color: $foreground-blue;
    border: 1px solid $foreground-blue-inactive;
    border-radius: 2px;
    background-color: transparent;

    &:focus {
      box-shadow: none;
      border-color: $gold-gradient-dark;
      outline: none;
    }
  }

  .VirtualCardSummary-LockCardSwitch {
    transform: scale(1.2);

    .custom-control-label {
      cursor: pointer;

      &:before {
        background: $foreground-blue;
        border-color: $foreground-blue;
        box-shadow: 0px 2px 3px 1px $gray;
      }
    }

    .custom-control-input {
      &:focus ~ .custom-control-label::before {
        box-shadow: 0px 2px 3px 1px $gray;
      }

      &:focus:not(:checked) ~ .custom-control-label::before {
        border-color: $foreground-blue;
      }

      &:checked ~ .custom-control-label::before {
        background: $accent-gold;
        border-color: $accent-gold;
        color: $white;
      }
    }
  }

  .CloseCardPermanently {
    color: $alert-red !important;
    font-size: 12px;
  }

  .Download-CSV {
    background-image: url("/assets/old/img/global/download.svg");
    height: 24px;
    width: 24px;
  }
}

.cancelCardModal {
  @media only screen and (min-width: 768px) {
    width: 370px !important;
  }
}

.CardMockContainer {
  aspect-ratio: 360/218;
  width: 408px;
  min-width: 312px;
  height: auto;
  background-color: $taekus-blue;
  padding-top: 70px;
  border-radius: 16px;
  overflow: hidden;
  font-family: 'Monument Grotesk', Consolas, Courier, monospace;
  font-weight: normal;
  color: white;

  @media only screen and (max-width: 767px) {
    transform: scale(0.9);
    min-width: 200px;
    max-width: 380px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    transform: scale(0.7);
    min-width: 218px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px)  {
    transform: scale(0.85);
    min-width: 289px;
  }

  .Microchip {
    width: 45px;
    padding-left: 5px;
  }

  .MastercardLogo {
    height: 40px;
    width: 70px;
    margin-right: -5px;
    margin-top: -15px;
  }

  .card-logo {
    opacity: 0.5;
    filter: invert(100%);
    position: absolute;
  }
  
  .cardName {
    text-align: center;
  }

  #copy-details-message::first-letter, .cardName {
    text-transform: capitalize;
  }

  .ForceMono {
    font-family: 'Monument Grotesk', Consolas, Courier, monospace !important;
    white-space:nowrap;
  }

  #card-content {
    min-height: 175px;
  }

  #mq-card-pan {
    margin-top: -26px;
  }

  #mq-card-exp, #mq-card-cvv {
    margin-top: -20px;
  }

  iframe[name="mq-card-pan"]{
    width: 100%;
    height: 25px;
  }

   iframe[name="mq-card-exp"] {
     height: 20px;
     width: 95%;
   }

   iframe[name="mq-card-cvv"] {
     height: 20px;
     width: 95%;
   }

  .panContainer {
    min-height: 30px;
    max-height: 30px;
    height: 30px;
    padding-left: 2px;
    border-radius: 4px;
    width: 100%;
    font-family: 'Monument Grotesk';

    @media only screen and (min-width: 768px) {
      width: 90%;
    }


    &:hover {
      background-color: rgb(221 181 178 / 34%);
    }
  }

  .expDateContainer {
    min-height: 10px;
    max-height: 20px;
    border-radius: 2px;
    margin-right: 35px;
    padding-top: 1px;

    &:hover {
      background-color: rgb(221 181 178 / 34%);
    }
  }

  .cvvContainer {
    min-height: 10px;
    max-height: 20px;
    border-radius: 2px;
    padding-top: 1px;
    margin-right: 75%;

    &:hover {
      background-color: rgb(221 181 178 / 34%);
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin-right: 60%;
    }
  }

  .panClickToCopyButton {
    position: relative;
    min-height: 30px;
  }

  .lineTwoClickToCopyButton {
    position: relative;
    min-height: 20px;
  }
}

.VirtualCardSummaryMock {
  .card-logo {
    top: 25px;
    right: 45px;
  }
}

.QuickViewMock {
  .card-logo {
    top: 25px;
    right: 25px;
    @media only screen and (min-width: 1201px) {
      top: 72px;
      right: 110px;
    }
  }
}