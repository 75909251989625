@import "../../utils/typeography.scss";
@import "../../utils/colors.scss";

body {
    font-family: $font-dm-sans;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// These cause all bootstrap modals to follow our custom in-house style
.modal-backdrop {
    opacity: 0.5 !important;
    background-color: $background-blue;
}

.modal-open .container-fluid, .modal-open  .container {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.Container-fixed {
  padding: 0;
  position: absolute;
  width: 100%;
  left: 0;
  min-height: 100vh;
}

iframe {
  @media print {
    display: none;
  }
}