/*
This file contains all the core colors used in the application.
In general, other stylesheets should inherit from here.
*/

$background-blue: #01193D;
$foreground-blue: #2A4966;
$gold: #CD8F3E;
$accent-gold: #DDB582;

$background-blue-inactive: rgba(1, 25, 61, 0.4);
$foreground-blue-inactive: rgba(42, 73, 102, 0.4);
$background-blue-light: rgba(42, 73, 102, 0.2);
$background-blue-very-light: rgba(90, 115, 138, 0.1);

$gold-gradient-light: #FCE9D1;
$gold-gradient-dark: #DDB5B2;

$black: #000000;
$white: #ffffff;
$transparent-white: rgba(256,256,256, 0.4);
$off-white: #F9F9F9;
$transparent-gray: rgba(90, 115, 138, 0.1);
$gray: #C4C4C4;

$border-gray: #696969;

$alert-background: #FFF2F2;
$alert-red: #AC0000;
$alert-green: #007349;

$taekus-purple: #7C3D76;
$taekus-grey: #D9D9D9;
$taekus-blue: #1A2278;

/* legacy colors -- deprecated */

$page-background: #F0F0F0;
