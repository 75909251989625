@import "../../utils/colors.scss";
@import "../../utils/typeography.scss";

.react-datepicker-wrapper {
  width: 100%;
}

.dateInput {
  .form-control:focus,
  .form-control:active {
    box-shadow: none !important;
  }

  .react-datepicker {
    border-radius: 0;
    font-family: 'DM Sans', sans-serif;
  }

  .react-datepicker__day--in-selecting-range {
    border-radius: 0;
    background-color: $transparent-gray;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range:hover {
    background-color: $background-blue;
    border-radius: 0;
  }

  .react-datepicker__close-icon:after {
    background-color: transparent;
  }
}


.Common-datePicker {
  .react-datepicker__header {
    background-color: white;
    border: none;
  }


  .react-datepicker__close-icon {
    padding-top: 6px;
    &:after {
      color: white;
      background-color: $gray;
    }
  }
}

.Common-datepickerHeader {
  .datepickerTitle {
    font-family: "Monument Grotesk", Sans-Serif;
    font-weight: 700;
    color: $taekus-blue;
    font-size: 0.95em;
  }

  .datepickerButton {
    color: $taekus-grey;
    font-weight: 600;
  }

  .datepickerButton-disabled {
    color: $black;
  }
}

.Common-datePickerCalendar {
  padding: 10px 20px 15px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 3px 1px $gray;

  .react-datepicker__day--in-selecting-range {
    border-radius: 5px;
    color: $taekus-blue;
    background-color: $transparent-gray;
  }

  .react-datepicker__day-name {
    color: $taekus-blue;
    font-family: "Monument Grotesk", Sans-Serif;
    text-transform: uppercase;
    font-size: 0.4em;
    line-height: 0.6em;
    margin-bottom: 0;
    font-weight: 700;
  }

  .react-datepicker__month {
    margin-top: 0;
  }

  .react-datepicker__day {
    font-family: "Monument Grotesk", Sans-Serif;
    color: $taekus-blue;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-selecting-range:hover {
    color: $taekus-blue;
    color: $white;
    border-radius: 5px;
  }

  .react-datepicker__day--selected {
    z-index: 1 !important;

    &::target-text {
      z-index: 10 !important;
    }
  }

  .react-datepicker__day--in-range {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    width: 2rem;
    background-color: $background-blue-light;
    color: $taekus-blue;

    &.react-datepicker__day--range-start {
      color: $white;
      background-color: $background-blue;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.react-datepicker__day--range-end {
      color: $white;
      background-color: $background-blue;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .react-datepicker__day--disabled {
    color: $gray;
  }
}
