@import "../../../../utils/colors.scss";


.taekusLogo {
  width: 30px;
  height: 40px;
  transform: translateY(-5px);
}

.taekusLogo-cash {
  width: 76px;
  height: 75px;
  transform: translateY(-5px);
}

.taekus-paymentBox {
  color: $background-blue;
  border: 2px solid $background-blue;
  border-right: 1px solid $background-blue;
  border-radius: 0;

  .transferArrow {
    position: absolute;
    right: -1.2em;
    width: 2em;
    height: 2em;
  }
}

.paymentBox-cash {
  font-size: 1.8rem;
}

.bookingPartner-paymentBox {
  border: 2px solid;
  border-left: 1px solid;
  border-radius: 0;
}

.transferNotice {
  color: #707070;
  font-size: 1.5em;
}

.fullLogo img {
  width: 80px;
  height: 40px;

  @media screen and (min-width: 480px) {
    width: 150px;
    height: 75px;
  }
}

.bookButtonContainer {
  @media screen and (max-width: 479px) {
    justify-content: center;
    margin-bottom: 2em;
  }
  @media screen and (min-width: 480px) {
    justify-content: flex-end;
  }

  .bookButton.btn.btn-secondary {
    background-color: white;
    border-color: $background-blue;
    color: $background-blue;
  }
}
