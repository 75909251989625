@import "../../../utils/typeography.scss";
@import "../../../utils/colors.scss";
@import "../../../utils/buttons.scss";


$char-w: 1ch;
$gap: .5*$char-w;
$n-char: 9;
$in-w: $n-char*($char-w + $gap);


.Payments {
  .Payments-TransferButton {
    width: 80px;
    font-weight: 600;
  }

  .PaymentButtonBlue {
    background: $background-blue-very-light;
    color: $black;
    height: 40px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    filter: $button-drop-shadow;

    &:hover {
      border: 1px solid $background-blue-light;
      border-radius: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  .PaymentButtonGreyedOut {
    background: $white;
    color: $gray;
    border-radius: 5px;
    height: 40px;
    border: 1px solid $transparent-gray;

    &:hover {
      border: 1px solid $background-blue-light;
      border-radius: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  .Payments-submitButton {
    height: 40px;
    width: 200px;
    font-weight: 600;
  }

  .complianceCheckboxContainer {
    .custom-control-label {
      padding-top: 1.5px;
    }
    .custom-control-input:checked~.custom-control-label:before {
      background-color: $background-blue;
      border-color: $background-blue;
    }
    .custom-control-input:focus~.custom-control-label:before {
      border-color: $background-blue;
      box-shadow: 0 0 0 0.2rem $background-blue-light;
    }
  }

  .Payments-customAmountInput {
    margin-left: 4px;
    border: none;
    padding: 0;
    width: $in-w;
    height: 20px;
    // This background is custom code to create a one-character-per-underline effect
    background: repeating-linear-gradient(90deg,
        dimgrey 0, dimgrey $char-w,
        transparent 0, transparent $char-w + $gap)
        0 100%/ #{$in-w - $gap} 2px no-repeat;
    font: 1.5ch monospace;
    letter-spacing: $gap;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .Payments-SelectCircle {
    border: 2px solid $background-blue;
    border-radius: 50px;
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-top: 5px;

    &.SelectCircleActive {
      border-color: $gold-gradient-dark;
      background: $gold-gradient-light;
    }
  }

  .Payments-VerifyOwnershipButton {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      border: none;
      background: transparent;
      outline: none;
    }
  }

  .Payments-SelectBox {
    border: 2px solid $background-blue;
    border-radius: 4px;
    cursor: pointer;

    .SelectBoxTitle {
      font-size: 0.82em;
      font-weight: 500;
    }

    &.SelectBoxActive {
      border-color: $gold-gradient-dark;
    }

    &.SelectBoxInactive {
      border-color: $gray;
      background: $transparent-gray;
    }
  }

  .Payments-datepickerWrapper {
    width: 100%;

    .Common-datePicker {
      width: 100%;
      margin-left: 10px;

      .react-datepicker__close-icon {
        padding-top: 0;
      }
    }

    .Payments-datepicker {
      &:focus,
      &:active {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }

    .Payments-calendarGlyph {
      transform: scale(0.7);
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.Payments-TransactionTableBody {
  min-height: 350px;

  .Payments-TransactionTableRow {
    border-bottom: 1px solid $transparent-gray;
  }
}

.Payments-modal {
  #setPINFrame {
    height: 270px;
  }

  #activateCardFrame {
    height: 300px;
  }

  &.disputeModal {
    .disputeModal-transactionBox {
      border: 1px solid $background-blue;
      border-radius: 2px;

      .transactionBox-title {
        color: $border-gray;
      }
    }

    .disputeModal-SelectBox {
      border: 2px solid $background-blue;
      border-radius: 4px;

      .SelectBoxTitle {
        font-size: 0.82em;
        font-weight: 500;
      }

      &.SelectBoxActive {
        border-color: $gold-gradient-dark;
      }
    }

    .disputeModal-SelectCircle {
      border: 2px solid $background-blue;
      border-radius: 50px;
      min-width: 12px;
      min-height: 12px;
      margin-left: 5px;
      margin-top: 5px;

      &.SelectCircleActive {
        border-color: $gold-gradient-dark;
        background: $gold-gradient-light;
      }
    }
  }

  &.confirmCardModal {
    @media only screen and (min-width: 768px) {
      width: 350px;
      height: 400px;
    }

    .loadingBody {
      min-height: 350px;
    }
  }


  &.autoReloadModal,
  &.disputeModal {
    @media only screen and (min-width: 768px) {
      width: 420px;
    }
  }

  .Payments-modalButton {
    flex-basis: 100%;
    height: 32px;

    @media only screen and (max-width: 768px) {
      font-size: 0.75em;
    }
  }

  .Payments-modalInputTitle {
    font: $font-dm-sans;
    color: $background-blue;
    font-size: 0.75em;
    font-weight: 600;
  }

  .Payments-modalInputContainer {
    .Payments-modalInputLabel {
      z-index: 10;
      position: absolute;
      color: #888888;
      padding-left: 0.85rem;
      padding-top: 0.3rem;
    }
    .Payments-modalInput {
      height: 55px;
      // these styles are slightly overspecified for normal inputs to accommodate consistent styling of different
      // react input components (currency input, date input etc). Note react-select uses a corresponding prop directly
      // in the JS (ugh)
      border: 1px solid $border-gray;
      border-radius: 0.25rem;
      padding-left: 1em;
      padding-top: 0.6em;

      &:focus,
      &:active {
        outline: none;
        border-color: $gold-gradient-dark;
        box-shadow: 0 0 3px $gold-gradient-dark;
      }
    }
  }

  .Payments-modalTextArea {
    height: 100px !important;
  }

  .modal-address {
    width: 450px;
  }
}

.Payments-settingsLink {
  background: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  border: none;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.Payments-header {
  .Payments-headerContent {
    color: $foreground-blue;
    font-size: 1.2em;
    font-family: $font-dm-sans;
    font-weight: 400;
  }

  .Payments-headerAutoReload {
    width: 200px;
  }
}

.Payments-confirmationPage {
  .PaymentsConfirmationPage-submit {
    font-size: 0.8em;
    font-weight: 600;
    width: 120px;
    height: 28px;
  }

  .Payments-confirmationPage-clear {
    height: 40px;
    width: 200px;
    font-weight: 600;
  }

  .Payments-confirmationTitle {
    font-size: $h1-default-size * 0.267;
  }
}

.addressLineTwoPrefix {
  color: #495057;
  width: 70px;
}

.Signup-Input {
  border: none;
  outline: none;
  border-radius: 0;
  border-bottom: 1px solid $foreground-blue;

  &.Signup-GenderInput {
    border-radius: 0;
  }

  &:focus,
  &:active {
    border-bottom: 2px solid $gold;
    box-shadow: 0 5px 5px -5px $gray;
  }
}