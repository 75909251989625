.ReactSlackChat__chat__messages___1kJlp {
  margin-top: 40px;
}
.ReactSlackChat__helpHeader___1zfWG {
  padding: 8px;
}

.ReactSlackChat__card___3w9UW {
  z-index: 99999;
  height: 40px;
}

.ReactSlackChat__chat__input___1-Hr1 {
  width: 100%;
}
