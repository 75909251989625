@import "../../../utils/colors.scss";
@import "../../../utils/typeography.scss";

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/old/fonts/password_font.ttf');
}


.Signup-container {
  position: absolute;
  width: 100%;
  height: 100%;

  .Signup-formBlock {
    margin-top: 15vh;
  }

  .Signup-formButtonDisabled {
    width: 200px;
  }

  .Signup-decorativeBackground {
    background: $taekus-purple;
  }

  .Signup-title,
  .Signup-subtitle {
    color: $taekus-purple;
    font-weight: 400;
  }

  .Signup-Input {
    border: none;
    outline: none;
    border-radius: 0;
    border-bottom: 1px solid $taekus-grey;

    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;

    &.Signup-GenderInput {
      border-radius: 0;
    }

    &:focus,
    &:hover,
    &:target,
    &:active {
      border-bottom: 1px solid $taekus-purple;

      &.invalid {
        background-color: rgba(256,0,0,0.2);
        border-bottom: 1px solid darkred;
      }
    }
  }

  .addressLineTwoPrefix {
    color: #7c8f9f;
    // width: 86px;
    // margin-bottom: 4px;
    width: 70px;
  }

  .complianceCheckboxContainer {
    .custom-control-label {
      padding-top: 1.5px;
    }
    .custom-control-input:checked~.custom-control-label:before {
      background-color: $background-blue;
      border-color: $background-blue;
    }
    .custom-control-input:focus~.custom-control-label:before {
      border-color: $background-blue;
      box-shadow: 0 0 0 0.2rem $background-blue-light;
    }
  }

  .Signup-Input:active + .Signup-revealPassword,
  .Signup-Input:focus + .Signup-revealPassword {
    fill: $taekus-purple;
  }

  .Signup-Input:active + .Signup-hidePassword,
  .Signup-Input:focus + .Signup-hidePassword {
    fill: $taekus-purple;
  }

  .Signup-logo {
    width: 240px;
    height: 200px;
  }

  .Signup-TermsLink {
    line-height: 1;
  }

  .Taekus-Icon-Large {
    width: 200px;
    height: 200px;
  }

  .Signup-glyph {
    margin-top: 4px;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
    outline: none;
    position: absolute;
    @media (max-width: 1100px){
      right: 20px;
    }
    @media (min-width: 1100px) {
      right: 55px;
    }

    &.Signup-revealPassword {
      background-image: url("/assets/old/img/global/eye.svg");
    }

    &.Signup-hidePassword {
      background-image: url("/assets/old/img/global/closed-eye.svg");
    }
  }

  .HiddenInput {
    color: black;
    font-family: 'password';
  }

  .PhoneInputInput {
    border-radius: 0;
    border: none;
    outline: none;
  }
}

