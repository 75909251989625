/*
This file contains all the core typeography used in the application.
In general, other stylesheets should inherit from here.
*/

@import "./colors.scss";

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;0,700;1,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=DM+Sans:400,400i,500,500i,600,600i,700,700i&display=swap&subset=latin-ext');

$font-raleway: 'Raleway', sans-serif;
$font-dm-sans: 'DM Sans', sans-serif;


$h1-default-size: 3.25em;
$h2-default-size: $h1-default-size * 0.6;
$h3-default-size: $h1-default-size * 0.4;
$h5-default-size: $h1-default-size * 0.333;
$h6-default-size: $h1-default-size * 0.266;

$tablet-scale-factor: 0.65;
$phone-scale-factor: 0.8;


/*
Color-related modifier classes
*/

.text-taekusBlue {
  color: $foreground-blue !important;
}

.text-green {
  color: green !important;
}

.text-red {
  color: $alert-red !important;
}

.text-orange {
  color: orange !important;
}

.text-gray {
  color: $gray  !important;
}

.text-white {
  color: $white !important;
}

.text-black {
  color: $black !important;
}

/*
Font-weight-related modifier classes
*/

.text-regular {
  font-weight: 400 !important;
}

.text-semibold {
  font-weight: 600 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-bold-transition {
  font-weight: 700 !important;
  transition: font-weight .2s ease !important;
}

/*
Font-size-related modifier classes
*/

.text-size-05 {
  font-size: 0.5em !important;
}

.text-size-07 {
  font-size: 0.7em !important;
}

.text-size-08 {
  font-size: 0.8em !important;
}

.text-size-10 {
  font-size: 1em !important;
}

.text-size-14 {
  font-size: 1.4em !important;
}

.text-size-15 {
  font-size: 1.5em !important;
}

.text-size-20 {
  font-size: 2em !important;
}

/*
Misc. modifier classes
*/

.text-underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-raleway {
  font-family: $font-raleway;
}

// use this class to remove all css from the element.
.all-unset {
  all: unset;
}

h1.StandardTitle {
  color: $foreground-blue;
  font-size: $h1-default-size;
  text-shadow: 0px 3px 6px #00000034;
  font-family: $font-raleway;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: $h1-default-size * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h1-default-size * $phone-scale-factor;
  }
}

h2.StandardTitle {
  color: $foreground-blue;
  font-size: $h2-default-size;
  //text-shadow: 0px 2px 3px #00000034;
  font-family: $font-raleway;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: $h2-default-size * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h2-default-size * $phone-scale-factor;
  }
}

.yellowBar-TitleH2 {
  width: 2.2em;
  height: 0.2em;
  background: $gold-gradient-dark;
  margin: 1.0em 0 0.7em 0.5em;
}

h3.StandardTitle {
  color: $taekus-purple;
  font-size: $h3-default-size;
  font-family: $font-raleway;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: $h3-default-size * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h3-default-size * $phone-scale-factor;
  }
}

.yellowBar-TitleH3 {
  width: 1.3em;
  height: 0.13em;
  background: $gold-gradient-dark;
  margin: 0.65em 0 0.5em 0.5em;
}

h3.StandardTitleDMSans {
  color: $foreground-blue;
  font-size: $h3-default-size;
  font-family: $font-dm-sans;
  font-weight: 400;

  @media only screen and (max-width: 768px) {
    font-size: $h3-default-size * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h3-default-size * $phone-scale-factor;
  }
}

h5.StandardTitleDMSans {
  color: $taekus-purple;
  font-size: $h5-default-size;
  font-family: $font-dm-sans;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: $h5-default-size * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h5-default-size * $phone-scale-factor;
  }
}

h6.StandardTitleDMSans {
  color: $foreground-blue;
  font-size: $h6-default-size;
  font-family: $font-dm-sans;
  font-weight: 400;

  @media only screen and (max-width: 768px) {
    font-size: $h6-default-size * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h6-default-size * $phone-scale-factor;
  }
}

label.StandardBodyDMSans,
input.StandardBodyDMSans,
button.StandardBodyDMSans,
div.StandardBodyDMSans {
  color: black;
  font-size: $h1-default-size * 0.267;
  font-family: $font-dm-sans;
  font-weight: 400;

  @media only screen and (max-width: 768px) {
    font-size: $h3-default-size * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h3-default-size * $phone-scale-factor;
  }
}

div.StandardBodyExtraSmallMobile {
  @media only screen and (max-width: 465px) {
    font-size: $h3-default-size * $phone-scale-factor * 0.7 !important;
  }
}

div.LargeSubtitleRaleway {
  color: $foreground-blue;
  font-size: $h1-default-size * 0.23;
  font-family: $font-raleway;
  font-weight: 700;
  line-height: 144%;
  letter-spacing: 3px;

  @media only screen and (max-width: 768px) {
    font-size: $h1-default-size * 0.23 * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h1-default-size * 0.23 * $phone-scale-factor;
  }
}

div.StandardSubtitleRaleway {
  color: $foreground-blue;
  font-size: $h1-default-size * 0.2;
  font-family: $font-raleway;
  font-weight: 600;
  line-height: 144%;
  letter-spacing: 2px;

  @media only screen and (max-width: 768px) {
    font-size: $h1-default-size * 0.2 * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h1-default-size * 0.2 * $phone-scale-factor;
  }
}

label.StandardSubtitleDMSans,
button.StandardSubtitleDMSans,
p.StandardSubtitleDMSans,
div.StandardSubtitleDMSans {
  color: black;
  font-size: $h1-default-size * 0.25;
  font-family: $font-dm-sans;
  font-weight: 400;

  a {
    color: $taekus-purple;
    &hover {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 768px) {
    font-size: $h1-default-size * 0.2 * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h1-default-size * 0.2 * $phone-scale-factor;
  }
}

label.StandardMiniSubtitleDMSans,
button.StandardMiniSubtitleDMSans,
p.StandardMiniSubtitleDMSans,
div.StandardMiniSubtitleDMSans {
  color: $background-blue;
  font-size: $h1-default-size * 0.1;
  font-family: $font-dm-sans;
  font-weight: 400;

  @media only screen and (max-width: 768px) {
    font-size: $h1-default-size * 0.1 * $tablet-scale-factor;
  }
  @media only screen and (max-width: 465px) {
    font-size: $h1-default-size * 0.1 * $phone-scale-factor;
  }
}

button.StandardLinkPurple,
div.StandardLinkPurple,
a.StandardLinkPurple {
  color: $taekus-purple;
  cursor: pointer;
}

button.StandardLinkGold,
div.StandardLinkGold,
a.StandardLinkGold {
  color: $gold;
  cursor: pointer;
}