/*
This file contains all the core typeography used in the application.
In general, other stylesheets should inherit from here.
*/

@import "./colors.scss";

$button-drop-shadow: drop-shadow(0 4px 4px $gray);

button {
  &.StandardButtonNoStyles {
    background: transparent;
    border: none;

    &:focus,
    &:active {
      outline: none;
    }
  }

  &.StandardButtonLink {
    background: transparent;
    border: none;
    font-weight: 700;
    color: $foreground-blue;
  }

  &.StandardButtonBlue {
    background: $taekus-purple;
    font-family: 'Monument Grotesk';
    color: $white;
    height: 40px;
    width: 200px;
    font-weight: 600;
    border-radius: 0;
    border: none;
    filter: $button-drop-shadow;
  }

  &.StandardButtonGray {
    background: $background-blue-inactive;
    color: $white;
    border-radius: 5px;
    border: none;
    filter: $button-drop-shadow;
  }

  &.StandardButtonWhite {
    background: $taekus-purple;
    color: $white;
    border-radius: 0;
    font-family: 'Monument Grotesk';
    border: 0;
  }

  &.StandardButtonGold {
    background: $gold;
    color: $white;
    border-radius: 5px;
    border: none;
    width: 200px;
    font-weight: 600;
    filter: $button-drop-shadow;
  }

  &.StandardButtonGoldAccent {
    background: $accent-gold;
    color: $white;
    border-radius: 5px;
    border: none;
    width: 200px;
    font-weight: 600;
    filter: $button-drop-shadow;
  }

  &.StandardButtonWhiteGold {
    background: $white;
    color: $accent-gold;
    border-radius: 5px;
    border: none;
    width: 200px;
    filter: $button-drop-shadow;
  }

  &.StandardButtonPurple {
    background: #7C3D76;
    border-radius: 0;
    border: none;
    width: 200px;
    &:hover {
      background: #7C3D76;
    }
  }
}