@import "../../utils/colors.scss";

#ContainerImage {
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
  overflow: hidden;
  background-size: cover;
  background-blend-mode: normal; // places the image in front of the off-white color
  background-color: $off-white;

  @media print {
    height: auto;
    overflow: auto;
    max-height: none;
  }

  &.triangles {
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
